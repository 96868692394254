import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import {
	parseDate
} from '../../../../utils/convert.js'

export default {
	name: "CsiiLifeMedical",
	data() {
		return {
			token: '',
			patientUuid: '',
			show: false,
			date: '',
			minDate: new Date(2018, 0, 1),

			csiiLifeMedicalForm: {
				visitDate: '',
				hospitalName: '',
				hospitalCode: '',
				testReportResult1: '',
				testReportResult2: '',
				testReportResult3: '',
				testReportResult4: '',
				testReportResult5: '',
				testReportResult6: '',
				testReportResult7: '',
				testReportResult8: '',
				testReportResult9: '',
				testReportResult10: '',
				testReportResult11: '',
				testReportResult12: '',
				testReportResult13: '',
				testReportResult14: ''
			}
		}
	},
	created() {

	},
	mounted() {
		this.token = this.$route.query.token;
		this.patientUuid = this.$route.query.patientUuid;
	},
	methods: {
		querySearch(queryString, cb) {
		    if(queryString.length > 0) {
		        this.hosList = [];
		        this.$axios.get("/api/api/hospital/searchHospital/" + queryString).then(res => {
		            if(res.data.code === 200) {
		                let oneHos = {};
		                res.data.data.forEach(d => {
		                    oneHos = {
		                        value: d.name,
		                        code: d.code
		                    };
		                    this.hosList.push(oneHos);
		                });
		                cb(this.hosList);
		            }
		        });
		    }
		},
		handleSelect(item) {
		    this.csiiLifeMedicalForm.hospitalName = item.value;
		    this.csiiLifeMedicalForm.hospitalCode = item.code;
		},
		onSubmit() {
			let that = this;
			that.$set(that.csiiLifeMedicalForm, "userUuid", that.patientUuid);
			that.$set(that.csiiLifeMedicalForm, "visitDate", that.date);

			if (that.csiiLifeMedicalForm.testReportResult1.length > 9) {
				that.$message({
					message: "您填写的糖化血红蛋白文字过长，最多可以填写9个字！",
					type: "error",
				});
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult2.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的甘油三脂文字过长，最多可以填写9个字！",
						type: "error",
					});
				}, 5);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult3.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的写空腹血糖值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 10);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult4.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后1h血糖值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 15);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult5.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后2h血糖值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 20);
				return;
			} 
			if (that.csiiLifeMedicalForm.testReportResult6.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后3h血糖值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 25);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult7.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的空腹C肽值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 30);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult8.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后1hC肽值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 35);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult9.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后2hC肽值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 40);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult10.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后3hC肽值文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 45);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult11.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的空腹胰岛素文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 50);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult12.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后1h胰岛素文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 55);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult13.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后2h胰岛素文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 60);
				return;
			}
			if (that.csiiLifeMedicalForm.testReportResult14.length > 9) {
				setTimeout(() => {
					that.$message({
						message: "您填写的餐后3h胰岛素文字过长，最多可以填写9位数！",
						type: "error",
					});
				}, 65);
				return;
			}

			that.$axios({
				method: "post",
				url: "/api/api/patientCsiiMedicalRecord/create",
				data: that.csiiLifeMedicalForm
			}).then(res => {
				if (res.data.code === 200) {
					this.$router.push({
						path: "/csiiLifesMedicalList",
						query: {
							patientUuid: this.patientUuid,
						}
					});
					that.$message({
						message: "保存成功！",
						type: "success"
					});
				} else {
					that.$message({
						message: res.data.message,
						type: "error"
					});
				}
			});
		},
		onConfirm(d) {
			this.show = false;
			this.date = parseDate(d);
		},
	}
}
